<template>
  <address-com position="Partner"></address-com>
</template>

<script>
import addressCom from "./address.vue";
export default {
  components: {
    addressCom,
  },
  data() {
    return {};
  },
  methods:{

  }
};
</script>

<style lang="scss">
</style>